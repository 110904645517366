<template>
  <q-card
    class="full-width row wrap justify-center items-center content-start q-pa-none q-ma-none"
  >
    <div ref="myDiv" class="full-width" style=""></div>
  </q-card>
</template>

<style></style>

<script>
import {
  // defineAsyncComponent,
  ref,
  onMounted,
} from "vue";

export default {
  name: "chartsolicitudesseguimiento",
  components: {
    // SolicitudesNuevas: defineAsyncComponent(() =>
    //   import("@/views/vinculacionexterna/components/SolicitudesNuevas.vue")
    // ),
    // SolicitudesSeguimiento: defineAsyncComponent(() =>
    //   import("@/views/vinculacionexterna/components/SolicitudesSeguimiento.vue")
    // ),
    // AnimalApi: defineAsyncComponent(() =>
    //   import("@/components/examples/AnimalApi.vue")
    // ),
  },

  setup() {
    console.log("ChartSolicitudesSeguimiento ready");
    const myDiv = ref(null);
    const trace1 = ref({
      x: ["2020-10-04", "2021-11-04", "2023-12-04"],
      y: [90, 40, 60],
      type: "scatter",
    });

    const data = ref([trace1.value]);

    const layout = ref({
      title: "Scroll and Zoom",
      uirevision: "true",
      showlegend: false,
      paper_bgcolor: "rgba(255, 255, 255, 0.7)",
      plot_bgcolor: "rgba(255, 255, 255, 0.3)",
      xaxis: {
        color: "rgba(0, 0, 0, 0.7)",
      },
      yaxis: {
        color: "rgba(0, 0, 0, 0.3)",
      },
    });
    window.Plotly.setPlotConfig({ locale: "es-MX" });
    onMounted(() => {
      window.Plotly.newPlot(myDiv.value, data.value, layout.value, {
        scrollZoom: true,
        displaylogo: false,
        responsive: true,
      });
      // window.Plotly.react(myDiv.value, data.value, layout.value, {
      //   scrollZoom: true,
      //   displaylogo: false,
      //   responsive: true,
      // });
    });
    return { myDiv };
  },
};
</script>

<style></style>
